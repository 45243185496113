.DetailsPage {
    .container {
        padding: 0;
    }

    .LicenseDetail {
        margin-bottom: 0.5rem;
    }

    section {
        padding: 0;
        margin: 0 0 2rem 0;
    }

    #map { 
        height: 25rem; 
        width: 100%;
        margin: 1rem 0;
    }

    .icon {
        width: 2.5rem;
    }

    .checkmark {
        color: #046B99
    }

    .exclamation {
        color: darkorange;
    }

    .idcard {
        color: slategray;
    }

    .status { 
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: .5rem;
        color: black;
    }

    .number {
        font-weight: bold;
    }

    .info {
        display: block;
        margin-left: 1rem;
    }

    .license-card {
        padding: 1rem;
        background-color: ghostwhite;
        color: dimgray;
    }

    strong {
        color: black;
    }

    .license-card-not-active {
        border-left: .5rem darkorange solid;
    }

    .license-card-active {
        border-left: .5rem #046B99 solid;
    }

    .license-card-business {
        border-left: .5rem slategray solid;
    }

    .license-card-location {
        border-left: .5rem wheat solid;
    }
}