.breadcrumb {
    font-size: 18px;
    padding: 0;
    margin: 0;
    A {
        margin-right: 4px;
    }
    Button {
        padding: 0;
        color: #046b99;
        font-size: 18px;
        bottom: 2px;
        position: relative;
    }
    Button:hover {
        padding: 0;
        text-decoration: underline;
    }
}
