@import '../library/variables.scss';

/* site footer */
section.site-footer {
	.container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		box-sizing: border-box;
		max-width: $w-lg;
		margin: 0 auto;
		padding: 1rem;
	}

	a {
		color: $primary-dark;
		&:hover {
			color: $primary-dark;
		}
		&:focus {
			outline: 2px solid $secondary;
		}
	}

	.footer-logo {
		width: 54px;
		margin-right: 2rem;
		img {
			width: 100%;
		}
	}

	.footer-secondary-links {
		display: flex;
		flex-direction: column;
		font-size: 1.2rem;
		a {
			margin-right: 1.7rem;
			padding-top: .5rem;
			padding-bottom: .5rem;
		}
	}

	.footer-social-links {
		padding-top: .7rem;
		padding-bottom: .7rem;
		a {
			text-decoration: none;
			padding-right: .3rem;
			padding-left: .3rem;
			transition: all 0.3s ease;
			svg path {
				fill: $primary-dark;
			}
			&:hover {
				svg path {
					fill: $primary;
				}
			}
		}
	}
}


@media (min-width: 991px) {
	.footer-secondary-links, section.site-footer .container {
		flex-direction: row !important;
		align-items:center;
	}
	.footer-social-links {
		margin-left: auto;

	}
}

// Misc
.bg-light-grey {
    background-color:$gray-100;
}

.pt-0 {
	padding-top: 0 !important;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}