.Modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);

  .Modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .Modal__header-title {
      margin: 0;
    }
  }

  .Modal__content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30vw;

    @media screen and (max-width: $screen-md-min) {
      width: 60vw;
    }

    @media screen and (max-width: $screen-sm-min) {
      width: 90vw;
    }
  }

  .Modal__dismiss {
    font-size: 30px;
    cursor: pointer;
  }
}
