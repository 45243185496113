.ResultsPage__search {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .search-box {
    flex: 4 0 300px;
  }

  .ResultsPage__search-controls {
    display: flex;
    flex: 1 0 360px;
    justify-content: flex-end;

    > * {
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }

    .export-button {
      margin-bottom: 16px;
    }
  }
}

.focused-row {
  color: #212529;
  background-color: rgba(0,0,0,.075);
}

table {
  Button.btn:focus {
    outline: rgba(0,0,0,.075);
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}
