.RetailersMapPage {
    #map { 
        height: 65vh; 
        width: 100%;
        margin: 0;
        padding: .75rem 0 1rem 0;
        position: sticky;
        top: 0;
    }

    .page-container {
        margin-top: 20px;
    }

    .map-container {
        padding-left: 0;
        z-index: 200;
        background-color: white;
    }

    .emailLink {
        background-color: white;
    }

    @media screen and (max-width: 998px) {
        #map {
            height: 45vh;
        }

        .map-container {
            position: sticky;
            top:0;
        }

        .map-column {
            padding: 0;
        }
    
    }

    .remove-x-pad {
        padding-left: 0;
        padding-right: 0;
    }

    .add-border-top {
        border-top: 1px solid grey;
    }

    .listings {
        max-height: 70vh;
        overflow-y: auto;

        @media screen and (max-width: 998px) {
            max-height: 45vh;
            padding: 0;
        }

        .not-found {
            padding: 0 .5rem;
        }
        
        h2 {
            margin-top: 0;
        }

        .RetailerListing {
            padding: 0.75rem .5rem 1rem .5rem;
            border-bottom: 1px solid grey;
            cursor: pointer;
    
            .Business {
                display: block;
                font-size: 1.4rem;
            }

            .Address, .Type {
                display: block;
            }

            .info-col {
                padding-left: 0;
            }

            .icon-col {
                padding-right: .5rem;
                padding-top: .2rem;
            }
        }

        .RetailerListing:first-child {
            padding-top: 0;
        }

        .RetailerListing:last-child {
            border-bottom: none;
        }
    }

    .highlighted {
        background-color: cornsilk;
    }
}