.Panel {
  background: #fff;
  z-index: $panel-zindex;
  padding: 15px;
  border: 1px solid #333;
  margin-top: -1px;
  width: 100%;

  h2 {
    margin: 0 0 15px 0;
  }
}

.PanelButton {
  margin-bottom: 15px;

  &.PanelButton--active {
    z-index: $panel-button-zindex;
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
  }
}
