.UnlicensedComplaintPage__section {
  border: 1px solid #999;
  padding: 15px;
  margin-bottom: 15px;

  @media (max-width: $screen-sm-min) {
    border-right: none;
    border-left: none;
    border-top: none;
  }
}

.UnlicensedComplaintPage__authority-desc {
  margin-bottom: 15px;
}

.UnlicensedComplaintPage__disclaimer {
  margin: 30px 0;
}

.UnlicensedComplaintPage__actions {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;

  .UnlicensedComplaintPage__actions-group {
    > * {
      margin-left: 15px;
    }
  }
}
