.App__route-container {
    min-height: 77vh;
    position: relative;
    padding-bottom: 80px;
}

.grecaptcha-badge { 
    bottom:95px !important; 
}

select::-ms-expand {
    display: none;
}

.App_disclaimer {
    bottom: 0;
    position: absolute;
}

#root {
    @media screen and (min-width: 1280px){ 
        .container {
            max-width: 1500px;
        }
        .branding {
            max-width: 100%;
        }
    }
}

.utility-header {
    background: #536043;
}
.navigation-search {
    border-bottom-color: #c3d69b;
}

a.first-level-link span {
    color: #c3d69b !important;
}

a.first-level-link:hover span {
    color: #476 !important;
}

@media (min-width: 1475px) {
    .main-navigation {
        position:relative;
        right:-7rem;
    }
}

.leaflet-container:focus {
    outline: solid 2px #2ea3f2 !important;
}
.leaflet-control-zoom-in:focus {
    outline: solid 2px #2ea3f2 !important;
}
.leaflet-control-zoom-out:focus {
    outline: solid 2px #2ea3f2 !important;
}
.leaflet-bar-part:focus {
    outline: solid 2px #2ea3f2 !important;
}
.leaflet-control-attribution a {
    color: #046b99;
    text-decoration: underline;
}

.App_warning {
    padding: 0;
    width: 100%;
    box-shadow: 1px 1px 2px #e4e4e4;
}

.exclamation {
    color: darkorange;
    width: 25px;
}

.App_nav {
    color: #476;
}

.App_nav_icons {
    margin-top: 5px;
}

.ca-gov-icon-home {
    padding-top: 4px;
}