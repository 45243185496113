@import '../library/variables.scss';
/* footer */
footer {
	.container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		box-sizing: border-box;
		max-width: $w-lg;
		margin: 0 auto;
		padding: 1rem;
	}
	a {
		&:hover {
			color: $primary-dark;
		}
		&:focus {
			outline: 2px solid $secondary;
		}
	}

	.footer-secondary-links {
		display: flex;
		flex-direction: column;
		font-size: 1.2rem;

		a {
			margin-right: 1.7rem;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
	}

	.bg-light-grey svg {
		padding: 0;
		.ca {
			fill: $secondary;
		}

		.gov {
			fill: $primary-dark;
		}
	}

	.cagov-logo {
		padding: 5px 2rem 5px 0;
	}

	.bg-light-grey a,
	.copyright {
		color: $black;
	}
}

.bg-light-grey {
	background-color: $gray-100;
}

.pt-0 {
	padding-top: 0 !important;
}

@media (min-width: 991px) {
	.footer-secondary-links,
	footer .container {
		flex-direction: row;
		align-items: center;
	}
}