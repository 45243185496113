.location-search-box {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  .SearchBox__icon {
    position: absolute;
    top: 28px;
    right: 0;
    padding: 0 10px;
    height: 38px;
  }

  .SearchBox__autoSuggest {
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 300;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0; 
  }

  .SearchBox__autoSuggest-item {
    padding: 2px 5px;
    cursor: pointer;
  }
}

.SearchBox {
  position: relative;

  .SearchBox__icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    height: 38px;
  }
}
