.CustomizeResultsPanel__actions {
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0;

  .CustomizeResultsPanel__actions-left {
    > * {
      margin-right: 15px;
    }
  }
}
