.HomePage {
  max-width: 600px;
  margin: 30px auto;

  .HomePage__actions {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0 0;
    height: 54px;
  }
}
