.LicensingContact {
    margin-bottom: .7em;

    .LicensingContact__title {
        margin-bottom: 0;
    }
}

.LicensingContact__links {
    font-size: larger;
}