.FieldDateRange__group-label {
  font-weight: 700;
  margin-bottom: .3rem;
}

.FieldDateRange__fields {
  display: flex;
  flex-wrap: wrap;
}

.FieldDateRange__label {
  flex: 1 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
}

.FieldDateRange__field {
  flex: 4 0 100px;
}
