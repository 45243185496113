@import '../library/variables.scss';

/* branding */
.branding {
	box-sizing: border-box;
	background-color: $white;
	border-bottom: 1px solid $gray-300;
	.container {
		max-width: $w-lg;
		margin: 0 auto;
		padding: 10px 0 0 15px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		position: relative;
	}
	.logo {
		margin-bottom: 5px;
		&:focus {
			outline: 2px solid $secondary;
		}
	}

	.logo-img {
		margin-left: 10px;
	}
	.branding-logo {
		color: $black;
		text-decoration: none;
		margin-left: 10px;
		&:focus {
			outline: 2px solid $secondary;
		}
		.logo-text {
			padding-left: 5px;
			display: inline-block;

			
			.logo-dept {
				font-size: 1.2rem;
				font-weight: 700;
				margin: 0;
				padding: 0;
				transition: all 0.3s;
				display: block;
				margin-top: -3px;
			}
			.logo-state {
				font-size:.7rem;
				margin: 0;
				margin-top: 3px;
				padding: 0;
				transition: all 0.3s;
				display: block;
				letter-spacing: 2px;

			}
		}
	}

	.mobile-icons {
		margin-left: auto;
		display: flex;
		flex-wrap: wrap;
		position: absolute;
		right: 0px;
		top: 20px;
	}

	button.search-btn,
	button.menu-btn {
		border: none;
		background: transparent;
		overflow: visible;
		padding: 10px 10px;
		margin-right: 10px;
		color: $primary;
		

		&:hover {
			color: $primary-dark;

			.blue {
				fill: $primary-dark;
			}
		}
		&:focus {
			color: $primary-dark;
			outline: 2px solid $secondary;
		}

		svg {
			position: relative;
			top: 1px;
		}

		.blue {
			fill: $primary;
		}
	}


	// 

	// Search box
	.search-container {
		display: none; // display none in mobile
		position: absolute;
		right: 15px;
		top: 20px;
		width: 30%;

		&.show {
			display: block;
			position: relative;
			display: block;
			position: relative;
			width: 100%;
			background-color: $gray-100;
			padding: 20px 16px 20px 16px;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			border-top: 1px solid $gray-300;
			border-bottom: 1px solid $gray-300;
		}

		#site-search {
			display: flex;
			position: relative;
			// submit input
			.search-textfield {
				border: 1px solid;
				border-color: $primary-dark;
				border-radius: 4px;
				padding: 10px;
				width: 100%;
				font-size: 1rem;
			}
			// submit button
			.search-submit {
				position: relative;
				right: 5px;
				outline-offset: -2px;
				background-color: $primary-dark;
				border: 1px solid $primary-dark;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				padding: 7px 14px 4px 14px;
				&:hover {
					background-color: $primary;
					border-color: $primary;
				}
				&:focus {
					outline: 2px solid $secondary;
				}
				svg path {
					fill: $white;
				}
			}

			.search-close {
				color: $primary-dark;
				text-decoration: underline;
				border: none;
				background-color: $gray-100;
				padding: 0 15px 0 15px;
				&:hover {
					color: $primary;
					text-decoration: none;
				}
				&:focus {
					outline: 2px solid $secondary;
				}
			}
		}
	}
}



// Hamburger menu to X transition
.toggle-menu {
    position: relative;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    cursor: pointer;

	.menu-btn-text {
		margin-left: 25px;
	}

    span {
        display: block;
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        height: 2px;
        width: 20px;
        background: $primary;
        border-radius: 5px;
        opacity: 1;
        left: 10px;
        transform: rotate(0deg);
        transition: .15s ease-in-out;

        &:nth-child(1) {
            top: 15px;
        }

        &:nth-child(2), &:nth-child(3) {
            top: 22px;
        }

        &:nth-child(4) {
            top: 29px;
        }
    }

	&:hover, &:focus {
		span {
			background: $primary-dark;
		}
	}

    &[aria-expanded="true"] {
        span:nth-child(1) {
            top: 12px;
            width: 0%;
            left: 20%;
        }

        span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        span:nth-child(4) {
            top: 12px;
            width: 0%;
            left: 20%;
        }
    }
}

@media (min-width: 768px) {
	.mobile-icons,
	.search-close {
		display: none !important;
	}
	.search-container {
		display: block !important;
	}
}

@media (min-width: 750px){
	.branding{
		max-width: 100%;
	}
}